
import ApiService from './ApiService'
import { initializeApp } from 'firebase/app'
import { getFirestore, doc, setDoc } from "firebase/firestore";
import {auth,signInWithEmailAndPassword,createUserWithEmailAndPassword,signOut,decodedToken,currentUser} from '../firebase'
import firebaseConfig from 'configs/firebase.config'

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)

export async function apiSignIn ({email,password}) {
    return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

export async function apiSignUp ({email,password}) {
    return await createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // Save extra user data in Firestore
            await setDoc(doc(db, 'users', user.uid), {
              // Replace these with your actual data
              attribute1: "value1",
              attribute2: "value2",
            });
            return user;
        })
        .catch((error) => {
            return error;
        });
}

// export async function apiSignUp ({email,password}) {
//     return createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
// }

export async function apiSignOut () {
    return await signOut(auth).then(user => user).catch(err => err);
}


